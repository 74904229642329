<script setup>
import { ref, computed } from "vue"
import { useStore } from 'vuex'
import { mdiCheck, mdiShieldCheck, mdiSpeedometer } from '@mdi/js'
import Icon from '@/components/Icon.vue'
import JbButton from '@/components/JbButton.vue'
import GLOBAL_URLS from "@/globals/api"

const store = useStore()
const userId = computed(() => store.state.auth.user.user.id)
const spinnerDisplayed = ref(false)

const formatNumber = (number) => {
    return new Intl.NumberFormat('en-EU', {
        style: 'decimal',
        maximumFractionDigits: 0
    }).format(number);
};

const plans = [
    {
        name: 'Starter',
        price: 15,
        priceId: 'price_1QIrFpGXiX2siKUDWuKbaEny',
        browserLoads: 16500,
        directRequests: 16500 * 5,
        popular: false
    },
    {
        name: 'Starter +',
        price: 49,
        priceId: 'price_1QL2cnGXiX2siKUDztDvgS74',
        browserLoads: 59290,
        directRequests: 59290 * 5,
        popular: true
    },
    {
        name: 'Professional',
        price: 99,
        priceId: 'price_1QIrG6GXiX2siKUDx1JqN2D9',
        browserLoads: 125235,
        directRequests: 125235 * 5,
        popular: false
    },
    {
        name: 'Enterprise',
        price: 499,
        priceId: 'price_1QIrGLGXiX2siKUDohwQKbmo',
        browserLoads: 672402,
        directRequests: 672402 * 5,
        popular: false
    }
]

const subscribe = (priceId) => {
    spinnerDisplayed.value = true
    const url = `${GLOBAL_URLS.STRIPE_URL}/create-subscription-session?plan=${priceId}&user_id=${userId.value}&reference=subscription_${Date.now()}`
    window.location.href = url
}
</script>

<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center mb-8">
            <h1 class="text-4xl font-bold bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
                Choose Your Plan
            </h1>
            <p class="mt-4 text-gray-600 dark:text-gray-400">
                All plans include premium proxies and advanced anti-bot protection
            </p>
        </div>

        <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div v-for="plan in plans" :key="plan.name"
                class="relative bg-white dark:bg-gray-800 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105"
                :class="{'ring-2 ring-blue-500': plan.popular}">
                
                <div v-if="plan.popular" 
                    class="absolute -top-4 left-1/2 -translate-x-1/2 bg-gradient-to-r from-blue-600 to-blue-400 
                    px-4 py-1 rounded-full text-white text-sm font-bold shadow-lg animate-pulse">
                    Most Popular
                </div>

                <div class="p-6">
                    <!-- Plan Header -->
                    <div class="text-center mb-6">
                        <h3 class="text-2xl font-bold mb-2" 
                            :class="plan.popular ? 'text-blue-600 dark:text-blue-400' : 'text-gray-900 dark:text-white'">
                            {{ plan.name }}
                        </h3>
                        <div class="flex items-center justify-center">
                            <span class="text-4xl font-extrabold">€{{ formatNumber(plan.price) }}</span>
                            <span class="ml-2 text-gray-500">/month</span>
                        </div>
                    </div>

                    <!-- Plan Features -->
                    <div class="space-y-4 mb-8">
                        <!-- Browser Loads -->
                        <div class="flex items-center space-x-2">
                            <Icon :path="mdiSpeedometer" class="w-5 h-5 text-blue-500"/>
                            <div>
                                <span class="font-medium">{{ formatNumber(plan.browserLoads) }}</span>
                                <span class="text-gray-600"> browser loads</span>
                            </div>
                        </div>

                        <!-- Direct Requests -->
                        <div class="flex items-center space-x-2">
                            <Icon :path="mdiShieldCheck" class="w-5 h-5 text-green-500"/>
                            <div>
                                <span class="font-medium">{{ formatNumber(plan.directRequests) }}</span>
                                <span class="text-gray-600"> direct requests</span>
                            </div>
                        </div>

                        <div class="pt-2 border-t border-gray-100 dark:border-gray-700">
                            <div class="text-sm text-gray-600 dark:text-gray-400">
                                <div class="flex items-center space-x-2 mb-1">
                                    <Icon :path="mdiCheck" class="w-4 h-4 text-green-500"/>
                                    <span>Residential proxies included</span>
                                </div>
                                <div class="flex items-center space-x-2">
                                    <Icon :path="mdiCheck" class="w-4 h-4 text-green-500"/>
                                    <span>All features included</span>
                                </div>
                            </div>
                            <p class="text-xs text-gray-500 mt-2">
                                Higher tiers get priority support
                            </p>
                        </div>
                    </div>

                    <!-- Subscribe Button -->
                    <jb-button 
                        :spinner="spinnerDisplayed"
                        @click="subscribe(plan.priceId)"
                        class="w-full transform transition-all duration-300 hover:-translate-y-1"
                        :class="plan.popular ? 'animate-shimmer bg-gradient-to-r from-blue-500 via-blue-600 to-blue-500 bg-[length:200%_100%]' : ''"
                        :color="plan.popular ? 'info' : 'white'"
                        :label="`Get ${plan.name}`"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@keyframes shimmer {
    0% { background-position: 100% 0; }
    100% { background-position: -100% 0; }
}

.animate-shimmer {
    animation: shimmer 3s infinite linear;
}

@media (max-width: 768px) {
    .grid { grid-template-columns: repeat(1, 1fr); }
}
</style>